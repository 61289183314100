import  {useUserAuth} from "../Context/UserAuthContext";
import {Navigate } from "react-router-dom";


export default function UserPrivateRoute({children}) {
    const {user} = useUserAuth();

    if(user.role === "user" || user.role ==="admin") {
        return children
    }
    return <Navigate to="/login"></Navigate>
}