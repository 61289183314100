import axios from "axios";


const BASE_URI = process.env.REACT_APP_BASE_PATH.toString();

async function getAllPatient() {
    return await axios.get(BASE_URI + "patient").then(res => res.status === 200 ? res : false)
        .catch(err => alert(err.toString()))

}

async function saveOrUpdatePatient(patient) {
    return await axios.post(BASE_URI + "patient", patient).then(res => res).catch(err => window.alert("Bir hata meydana geldi!"));
}


async function deletePatient(patient) {
    return await axios.delete(BASE_URI + "patient/" + patient.toString())
        .then(res => res)
        .catch(err => console.log(err))
}

async function updatePatient(patient) {
    return await axios.post(BASE_URI + "update/patient/",patient)
        .then(res => res)
        .catch(err => console.log(err))
}

export {getAllPatient, deletePatient, saveOrUpdatePatient,updatePatient};