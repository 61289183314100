import {Tabs, TabList, TabPanels, Tab, TabPanel} from '@chakra-ui/react'
import StudentPage from "../Components/StudentPage";
import VideoPage from "../Components/VideoPage";
import Navbar from "../Components/Navbar";


export function AdminPage() {
    return (
        <>
           <Navbar></Navbar>
            <div className={"w-full h-full min-h-screen bg-white"}>
                <div className={" flex flex-wrap justify-center items-center"}>
                </div>
                <Tabs variant='soft-rounded' colorScheme='orange' className={"px-4 py-4 bg-white"}>
                    <TabList>
                        <Tab><p className={"text-sky-800 text-lg font-bold"}>Hasta İşlemleri</p></Tab>
                        <Tab><p className={"text-sky-800 text-lg font-bold"}>Video İşlemleri</p></Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <StudentPage></StudentPage>
                        </TabPanel>
                        <TabPanel>
                            <VideoPage></VideoPage>
                        </TabPanel>
                    </TabPanels>

                </Tabs>
            </div>

        </>
    )
}