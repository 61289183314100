import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css'
import "primereact/resources/themes/lara-light-blue/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";
import {BrowserRouter} from "react-router-dom";
import {UserAuthProvider} from "./Context/UserAuthContext";
import {ChakraProvider} from '@chakra-ui/react'//icons


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>

        <BrowserRouter>
            <UserAuthProvider>
                <ChakraProvider>
                    <App/>
                </ChakraProvider>
            </UserAuthProvider>
        </BrowserRouter>


    </React.StrictMode>
);
