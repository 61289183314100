import axios from 'axios';


const BASE_URI=process.env.REACT_APP_BASE_PATH.toString();
async function userLogin(user){
    return await axios.post(BASE_URI+"patient/login",user).then(res=>res);
}


export {userLogin}
