import Navbar from "../Components/Navbar";

function Home() {

    return (
        <>
            <Navbar/>

            <section className="px-2 py-32 bg-white md:px-0 h-screen pb-10 pb-5">
                <div className="container items-center max-w-6xl px-8 mx-auto xl:px-5">
                    <div className="flex flex-wrap items-center sm:-mx-3">
                        <div className="w-full md:w-1/2 md:px-3">
                            <div
                                className="w-full pb-6 space-y-6 sm:max-w-md lg:max-w-lg md:space-y-4 lg:space-y-8 xl:space-y-9 sm:pr-5 lg:pr-0 md:pb-0">
                                <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-4xl lg:text-2xl xl:text-5xl">
                                    <span className="block xl:inline">Çocuk Sahibi Olma Yolculuğunuzda </span>
                                    <br/>
                                    <span className="block text-orange-500 xl:inline"> Yalnız Değilsiniz !</span>
                                </h1>

                                <p className="mx-auto text-base text-gray-500 sm:max-w-md lg:text-md md:max-w-3xl text-justify">
                                    <p className={"text-xl font-bold"}>Aşılama Tedavi Süreci Eğitim Programına Hoşgeldiniz.
                                    </p>
                                    <p className={"mt-3"}>
                                        Bu program, aşılama olarak da bilinen İntrauterin İnseminasyon (IUI) tedavi yöntemi
                                        ile çocuk sahibi olma sürecinizde sizleri destekleyen bir izlem, eğitim ve
                                        danışmanlık programıdır. Amacımız; tedavi sürecinize yönelik verilen bu web tabanlı
                                        eğitimin infertilite stresi ile başa çıkma düzeyinize ve infertilite öz
                                        yeterliliğinize etkisini incelemektir.
                                    </p>

                                    <p className={"mt-3"}>
                                        Web sitemizde tedavi süreciniz hakkında bilgi edinebileceğiniz ve tedavi
                                        sürecinizi
                                        bağımsız olarak yönetebileceğiniz birçok eğitim videosu ve bilgilendirme içeriği
                                        yer
                                        almaktadır.
                                    </p>
                                    <p className={"mt-3"}>
                                        Projemizin sizler için faydalı olması dileğiyle…
                                    </p>


                                </p>
                                <div className="relative flex flex-col sm:flex-row sm:space-x-4">
                                    <a href="/login"
                                       className="flex items-center w-full px-6 py-3 mb-3 text-lg text-white bg-orange-500 rounded-md sm:mb-0 hover:bg-orange-600 sm:w-auto">
                                        Giriş Yap
                                    </a>

                                </div>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2">
                            <div className="w-full h-auto overflow-hidden rounded-md shadow-xl sm:rounded-xl mb-5">
                                <img src="home.jpg" className={"w-full"} alt={"deneme"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default Home;


