import './index.css'
import Home from "./Pages/Home";
import Info from "./Pages/Info";
import Contact from "./Pages/Contact";
import {Routes, Route} from "react-router-dom";
import Faq from "./Pages/Faq";
import {Login} from "./Pages/Login";
import {useState} from "react";
import StudentPage from "./Components/StudentPage";
import VideoPage from "./Components/VideoPage";
import AdminAuth from "./auth/AdminAuth";
import UserWatchVideo from "./Components/UserWatchVideo";
import AdminPrivateRoute from "./auth/AdminAuth";
import {AdminPage} from "./Pages/AdminPage";
import UserPrivateRoute from "./auth/UserAuth";
import Page404 from "./Pages/Page404";

function App() {


    return (
        <>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="hakkinda" element={<Info/>}/>
                <Route path="iletisim" element={<Contact/>}/>
                <Route path="login" element={<Login/>}/>
                <Route path="faqs" element={<Faq/>}/>
                <Route path="/video"
                       element={<UserPrivateRoute>
                           <UserWatchVideo/>
                       </UserPrivateRoute>}>
                </Route>
                <Route path="/admin"
                       element={<AdminAuth>
                           <AdminPage></AdminPage>
                       </AdminAuth>}>
                </Route>
                <Route path={"*"} element={<Page404></Page404>}/>
                />
            </Routes>
        </>
    );
}

export default App;


