import Navbar from "../Components/Navbar";

export function Info() {


    return (
        <>
            <Navbar/>
            <div className={"flex flex-col w-full h-full  text-slate-700 "}>
                <section className="w-full py-12  lg:py-24">
                    <div className="max-w-6xl px-12 mx-auto">
                        <div className="space-y-12 ">
                            <div className="max-w-3xl mb-20 space-y-5 sm:mx-auto sm:space-y-4 font-serif">
                                <h2 className="relative text-4xl font-extrabold tracking-tight sm:text-5xl text-orange-500">PROJE
                                    HAKKINDA</h2>
                                <p className="text-xl text-gray-800 text-justify">
                                    Gebe kalamama ya da gebe bırakamama olarak da
                                    bilinen infertilite, 1 yıl süre ile herhangi bir doğum kontrol yöntemi kullanmadan
                                    haftada en az 2-3 kez cinsel ilişkide bulunulmasına rağmen gebeliğin elde
                                    edilememesi durumudur. İnfertilite tedavisi ise anlaşılması zor ve karmaşık bir
                                    süreç olup, bu süreçte bireyin güçlendirilmesine yönelik profesyonel eğitim ve
                                    danışmanlık gerektirmektedir.
                                </p>
                                <br/>
                                <p className="text-xl text-gray-800 text-justify">
                                    Bu proje, aşılama olarak da bilinen İntrauterin İnseminasyon (IUI) tedavi yöntemi
                                    ile çocuk sahibi olma sürecinizde sizleri destekleyen bir izlem, eğitim ve
                                    danışmanlık programını kapsamaktadır. Projenin amacı; tedavi sürecinize ilişkin
                                    verilen web tabanlı eğitimin infertilite stresi ile başa çıkma düzeyinize ve
                                    infertilite öz yeterliliğinize etkisini incelemektir.
                                </p>
                                <br/>
                                <p className="text-xl text-gray-800 text-justify">
                                    Projemiz aşılama tedavinizin başlangıcından tedavi sonrası gebelik testinizi
                                    yaptırdığınız zamana kadar olan yaklaşık 24-27 günlük süreyi kapsamaktadır. Proje
                                    kapsamında web sitemizde tedavi süreciniz hakkında bilgileneceğiniz ve tedavi
                                    sürecinizi yönetebileceğiniz birçok eğitim videosu yer almaktadır.
                                    Projenin sizler için faydalı olması dileklerimizle…
                                </p>

                            </div>
                        </div>

                        <div className="w-full flex flex-col justify-center items-center ">
                            <div className="sm:w-full md:w-full lg:w-4/5 rounded-2xl h-full  text-amber-500 shadow-xl px-8 py-8  mb-4 mt-4">

                                <div className="flex flex-col items-center justify-center p-10">
                                    <img className="w-64 h-64 mb-6 rounded-2xl"
                                         src={"hilal.png"}/>
                                    <h2 className="text-2xl font-medium text-gray-900">Hilal KARADENİZ</h2>
                                    <p className="font-medium text-gray-900">ARAŞTIRMACI</p>
                                </div>

                                <div className="flex flex-col divide-gray-200 ">
                                    <p className={"font-bold text-2xl mt-3 mb-2 text-orange-500"}>Araştırmacı Hakkında</p>
                                    <p className={"font-serif pl-2 mt-3 text-justify sm:text-xs md:text-md lg:text-lg text-gray-900"}>
                                        Temmuz 1993 yılında Ankara’da doğdu. İlk, orta ve lise öğrenimini Ankara’da
                                        tamamladı.2011-2016 yılları arasında Ege Üniversitesi Hemşirelik Fakültesi’nde
                                        lisans eğitimini sürdürdü. 2016-2019 yılları arasında yüksek lisans eğitimini de
                                        Ege Üniversitesi’nde tamamladıktan sonra 2019 yılı itibari ile Marmara
                                        Üniversitesi, Sağlık Bilimleri Enstitüsü, Doğum ve Kadın Hastalıkları
                                        Hemşireliği Doktora Programı’nda eğitimine başladı. Bu süreçte özel kurumlarda
                                        Halk Sağlığı Hemşireliği ve İnfertilite Hemşireliği alanlarında görev yaptı. Şu
                                        anda Düzce Üniversitesi Doğum ve Kadın Hastalıkları Hemşireliği Anabilim
                                        Dalı’nda araştırma görevlisi olarak görev yapmakta olup, doktora tez araştırması
                                        olarak yer aldığınız bu projeyi yürütmektedir.
                                    </p>
                                </div>
                            </div>

                            <div className="sm:w-full md:w-full lg:w-4/5 rounded-2xl  h-full px-8 py-8 shadow-xl text-amber-500 mb-4 mt-4">

                                <div className="flex flex-col items-center justify-center p-10">
                                    <img className="w-64 h-64 mb-6 rounded-2xl"
                                         src={"nurdan.jpg"}/>
                                    <h2 className="text-2xl font-medium text-gray-900">Nurdan DEMİRCİ</h2>
                                    <p className="font-medium text-gray-900">ARAŞTIRMACI</p>
                                </div>

                                <div className="flex flex-col divide-gray-200 ">
                                    <p className={"font-bold text-2xl mt-3 mb-2 text-orange-500"}>Araştırmacı Hakkında</p>
                                    <p className={"font-serif pl-2 mt-3 text-justify sm:text-xs md:text-md lg:text-lg text-gray-900"}>
                                        1979-1983 yılları arasında lisans eğitimini, 1986-1989 yılları arasında yüksek
                                        lisans eğitimini ve 1989-1995 yılları arasında doktora eğitimini İstanbul
                                        Üniversitesi, Sağlık Bilimleri Enstitüsü, Kadın Sağlığı ve Hastalıkları
                                        Hemşireliği Anabilim Dalı’nda tamamladı. Bu süreçte Marmara Üniversitesi Sağlık
                                        Hizmetleri Meslek Yüksekokulu Müdürü ve Üreme Sağlığı ve İnfertilite Hemşireliği
                                        Derneği Başkanı olarak görev yaptı. Alanı ile ilgili birçok bilimsel yayını ve
                                        faaliyeti bulunan Demirci, 2016 yılından itibaren Marmara Üniversitesi, Sağlık
                                        Bilimleri Fakültesi, Hemşirelik Bölümü, Doğum ve Kadın Hastalıkları Hemşireliği
                                        Anabilim Dalı’nda profesör olarak görev yapmakta olup, doktora tezi kapsamında
                                        yürütülen bu projeye danışmanlık yapmaktadır.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    )
}

export default Info;