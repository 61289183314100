import {InputText} from "primereact/inputtext";
import Navbar from "../Components/Navbar";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {userLogin} from "../Services/LoginService";
import {useUserAuth} from "../Context/UserAuthContext";


export function Login() {

    const navigate = useNavigate();
    const {setUser} = useUserAuth();
    const [username, setUserName] = useState();
    const [password, setPassowrd] = useState();
    const {isAdmin,setIsAdmin} = useUserAuth();


    useEffect(()=> {
        if(isAdmin){
            navigate("/admin/hasta")
        }
    },[setIsAdmin])

    const Userlogin = () => {
        userLogin({username, password}).then(res => {
            if (res.status === 200) {
                setUser(res.data)
                if(res.data.role==="admin"){
                    setIsAdmin(true);
                    navigate("/admin")
                }
                else if (res.data.role ==="user"){
                    navigate("/video")
                }
                else {
                    navigate("/login")
                }
            }
        }).catch(e => {
            window.alert("Kullanıcı Adı veya Şifre Hatalı")
        })
    }



    return (
        <>
            <Navbar/>
            <div className="flex flex-col items-center justify-center w-full min-h-full py-16">
                <div className={"flex flex-col items-center justify-center w-full lg:w-1/2 h-full px-12 py-12 "}>
                    <label htmlFor="email"
                           className="block text-900 font-medium mb-2 text-orange-500 self-start">KULLANICI ADI</label>
                    <InputText id="tc" type="text"
                               placeholder={"Kullanıcı Adınızı Giriniz."}
                               className="w-full mb-3 placeholder:text-sm" maxLength={11} pattern={[0 - 9]}
                               value={username}
                               onChange={(e) => setUserName(e.target.value)}/>

                    <label htmlFor="password"
                           className="block text-900 font-medium mb-2 self-start text-orange-500 mt-4">ŞİFRE</label>
                    <InputText id="password" type="password" className="w-full mb-3 placeholder:text-sm"
                               placeholder={"Sizlere iletilen şifrenizi giriniz."}
                               value={password}
                               onChange={(e) => setPassowrd(e.target.value)}/>
                    <label className={"self-end text-sm text-slate-400 mt-1 px-2 py-2 "}><a
                        href={"mailto:hilalkaradeniz@duzce.edu.tr"}>Giriş esnasında problem yaşıyorsanız <strong>tıklayınız...</strong></a></label>


                    <a href={"/login"}
                       onClick={(e) => {
                           e.preventDefault();
                           Userlogin();
                       }}

                       className={"w-full lg:w-1/4 cursor-pointer text-center  bg-orange-500 px-8 py-4 rounded-xl font-bold text-xl text-white"}>Giriş
                        Yap</a>
                </div>

            </div>
        </>

    )
}