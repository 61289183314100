import Navbar from "../Components/Navbar";
import {Fieldset} from "primereact/fieldset";

export function Contact() {
    return (
        <>
            <Navbar/>
            <div
                className={"flex flex-col w-full h-full justify-center justify-items-center font-serif px-10 py-4 mt-4"}>
                <div className={"flex flex-col text-center"}>
                    <h1 className="text-5xl pb-5 font-bold text-orange-500 ">İletişim</h1>
                    <p className={"text-2xl font-bold mt-2 "}>Araştırmacı Hilal Karadeniz</p>
                    <hr/>
                </div>

                <div className={"text-md mt-2 flex mb-2 flex justify-items-center justify-center"}>
                    <Fieldset legend={"İş Telefonu"} className={"text-md mt-2 mb-2 w-full lg:w-1/2"}>
                        <p className={"ml-1 font-medium text-md"}>0380 542 11 41 / 3532</p>
                    </Fieldset>
                </div>

                <div className={"text-md mt-2 flex mb-2 flex justify-items-center justify-center"}>
                    <Fieldset legend={"Cep Telefonu"}  className={"text-md mt-2 mb-2 w-full lg:w-1/2"}>
                        <p className={"ml-1 font-medium text-md"}> 0538 692 49 93</p>
                    </Fieldset>
                </div>

                <div className={"text-md mt-2 flex mb-2 flex justify-items-center justify-center"}>
                    <Fieldset legend={"Fax"}  className={"text-md mt-2 mb-2 w-full lg:w-1/2"}>
                        <p className={"ml-1 font-medium text-md"}>0380
                            542 11 40</p>
                    </Fieldset>
                </div>

                <div className={"text-md mt-2 flex mb-2 flex justify-items-center justify-center"}>
                    <Fieldset legend={"Kişisel Mail"}  className={"text-md mt-2 mb-2 w-full lg:w-1/2"}>
                        <a className={"ml-1 font-medium text-md text-left text-center"}
                           href={"mailto:hilalkaradeniz1993@hotmail.com"}>hilalkaradeniz1993@hotmail.com</a>
                    </Fieldset>
                </div>

                <div className={"text-md mt-2 flex mb-2 flex justify-items-center justify-center"}>
                    <Fieldset legend={"İş Mail"} className={"text-md mt-2 mb-2 w-full lg:w-1/2"}>
                        <a className={"ml-1 font-medium text-md"}
                           href={"mailto:hilalkaradeniz@duzce.edu.tr"}>hilalkaradeniz@duzce.edu.tr </a>
                    </Fieldset>
                </div>

                <div className={"text-md mt-2 flex mb-2 flex justify-items-center justify-center"}>
                    <Fieldset legend={"Adres"} className={"text-md mt-2 flex mb-2 sm:w-full lg:w-1/2"}>
                        <address className={"text-md ml-1"}>Düzce Üniversitesi Konuralp Yerleşkesi, Sağlık
                            Bilimleri Fakültesi, Orman Fakültesi Hizmet Binası Bodrum 1. Kat, Merkez-DÜZCE
                        </address>
                    </Fieldset>
                </div>

            </div>
            <div className={"sm:h-96 md:h-96 lg:h-96 sm:w-full md:w-full lg:w-1/2 lg:p-5 flex m-auto p-4 mb-20"}>
                <iframe
                    title={"Map"}
                    className={"w-full h-full"}
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2041.9038606572965!2d31.174617893924697!3d40.903972681803104!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x409da11bd986c4ab%3A0xbd3cc9e8c9525ef!2zRMO8emNlIFTEsXA!5e0!3m2!1str!2str!4v1655346100159!5m2!1str!2str"
                    allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>

        </>
    )

}

export default Contact;