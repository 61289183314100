import axios from "axios";


const BASE_URI = process.env.REACT_APP_BASE_PATH.toString();

async function getVideoIstatisticById(id) {
    return await axios.get(BASE_URI + "istatistic/patient/"+id.toString()).then(res => res.status === 200 ? res : false)
        .catch(err => alert(err.toString()))

}

async function postVideoIstatistic(istatistic) {
    return await axios.post(BASE_URI + "istatistic",istatistic).then(res => res.status === 200 ? res : false)
        .catch(err => console.log(err.toString()))
}


async function updateVideoIstatistic(istatistic) {
    return await axios.post(BASE_URI + "update/istatistic",istatistic).then(res => res.status === 200 ? res : false)
        .catch(err => console.log(err.toString()))
}



export  {getVideoIstatisticById,postVideoIstatistic,updateVideoIstatistic};