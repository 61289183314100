import {Accordion, AccordionTab} from "primereact/accordion";
import Navbar from "../Components/Navbar";

export function Faq() {

    const faqs = [
        {
            title: "Yumurta büyütme ilaçlarımın dozlarını karıştırdım ve yanlış kullandım, ne yapmalıyım?",
            content: "Yumurta büyütme ilaçlarını yanlış kullandıysanız fark ettiğinizde vakit kaybetmeden doktorunuza veya hemşirenize başvurunuz. Doktorunuz yanlış kullandığınız ilaçları ve dozunuzu yumurta gelişiminize göre tekrar düzenleyerek tedavi sürecinizi tekrar gözden geçirecektir."
        },
        {
            title: "Çatlatma iğnemi zamanında uygulayamadım, uygularken sorun yaşadım, ne yapmalıyım?",
            content: "Aşılama işleminiz çatlatma iğneniz uygulandıktan 36 saat sonra yapılacaktır. Çatlatma iğnenizi size söylenen zamandan erken uygulamanız yumurtalarınızın tüplere geçmesine sebep olabilir. Dolayısıyla çatlatma iğnenizi uygulama zamanınız aşılama randevunuzu belirlemektedir. Bu nedenle size söylenen saatte çatlatma iğnenizi uygulayamamışsanız mutlaka doktorunuzu veya hemşirenizi uygulama zamanınız hakkında bilgilendirmelisiniz."
        },
        {
            title: "Aşılama işlemi sırasında ağrı hisseder miyim?",
            content: "Aşılama işlemi ağrısız bir işlemdir, bu nedenle ağrı duymanız beklenmez. Ancak işlem sırasında rahim ağzınızı görmek amacıyla haznenize yerleştirilen spekulum denen aletten dolayı rahatsızlık hissedebilirsiniz. "
        },
        {
            title: "Aşılama işlemi uygulandıktan sonra ağrı olması normal mi? ",
            content: "Aşılama işlemi sonrasında hafif düzeyde kasık ağrısı yaşanabilir. Bununla birlikte aşılama uygulanan her kadında ağrı olması beklenmez. Ağrı aşılamadan birkaç gün sonrasında halen devam ediyorsa veya bu süreçte şiddetlenmişse doktorunuza başvurmalısınız."
        },
        {
            title: "Aşılama işlemi uygulandıktan sonra kanama olması normal mi?",
            content: "Aşılama işleminden hemen sonra ya da bir iki hafta içinde lekelenme tarzında kanama görülebilir. Bununla birlikte aşılama uygulanan her kadında kanama olması beklenmez.  Aşılama işlemi sırasında kanama görülmesi işlem sırasında kullanılan aletlerin etkisiyle rahim ağzı bölgesinden kaynaklanmaktadır. Aşılamadan sonraki günlerde kanama yaşanmasının nedeni ise embriyonun rahim duvarına tutunmasıyla ilgilidir. Bu süreçte görülen kanama gebelik olasılığını etkilemez. Ancak kanamanın lekelenme tarzında olması normaldir. Kanamanın sürekli olması veya artması durumunda doktorunuza başvurunuz."
        },
        {
            title: "Aşılama işlemi sonrasında akıntı olması normal mi?",
            content: "Aşılamadan sonra akıntı olması beklenmez. Normalden fazla akıntı olması durumunda doktorunuza başvurunuz."
        },
        {
            title: "Aşılama işlemi sonrasında progesteron hormonu kullanmam gerekir mi?",
            content: "Aşılama sonrasında her kadının progesteron hormonu kullanması gerekmez. Progesteron hormonu kullanımına tedavi sürecinize göre doktorunuz karar verecektir. "
        },
        {
            title: "Cinsel ilişkiye aşılama işlemi sonrasında ne zaman girilebilir?",
            content: "Aşılamadan 24 saat sonra gün aşırı cinsel ilişki önerilmektedir. Bunun nedeni aşılama ile yakalanamayan ve sonradan çatlaması muhtemel olan yumurtaların sonradan girilen cinsel ilişki ile sperm tarafından döllenmesinin sağlanarak gebelik şansını artırmaktır. Gebe kaldıktan sonra ise cinsel ilişkinin devamlılığı hakkında doktorunuzdan bilgi alabilirsiniz."
        },
        {
            title: "Gebelik testini aşılama işleminden ne kadar süre sonra yaptırmalıyım?",
            content: "Aşılama uygulandıktan 14 gün sonra kan ya da idrarda gebelik testi yapılmalıdır. "
        },
        {
            title: "Aşılama sonrasında gebelik testi sonucum pozitif çıkarsa ne yapmalıyım? ",
            content: "Aşılama işleminden 14 gün sonra yapılan gebelik testi sonucu pozitif çıktığı takdirde bu durum gebe olduğunuzu gösterir. Gebelik kontrolü, düzenli takip süreci ve kullanılması gereken ilaçlar hakkında bilgi almak için doktorunuza veya hemşirenize başvurunuz."
        },
        {
            title: "Gebelik belirtileri aşılama sonrasında ne zaman görülür?",
            content: "Aşılama işlemi sonrasındaki gebelik belirtileri doğal yolla gebelik elde edilmesinden farksızdır. Baş dönmesi, halsizlik, mide bulantısı ve kusma gibi gebelik belirtileri genellikle aşılamadan üç hafta sonra görülebilir."
        },
        {
            title: "Aşılamadan sonra gebelik testi sonucum negatif olursa ne zaman adet görürüm?",
            content: "Aşılama sonrası gebelik testi negatif çıktığı takdirde ortalama 15-20 gün içerisinde adet görülmesi beklenir. Bu sürede adet görmemişseniz doktorunuza başvurunuz.",
        },
        {
            title: "Gebelik aşılama işleminden ne kadar süre sonra ultrasonda görülür?",
            content: "Aşılama sonrası gebelik kesesinin ultrasonda görülebilmesi için gebeliğin en az beş haftalık olması gerekir. Bu süre aşılamadan üç hafta sonraya denk gelmektedir. Aşılama yapıldıktan dört-beş hafta sonra ise embriyonun kalp atışları ultrasonla izlenebilir.",
        },
        {
            title: "Aşılama işlemi sonrasında ne zaman banyo yapabilirim?",
            content: "Aşılama ertesi günden itibaren banyo yapılabilir. Ancak bu süreçte uzun süre yüksek sıcaklıkta banyoda kalmak, hamam, sauna ya da termal havuz kullanmak sakıncalıdır. Bu dönemde küvet banyo ve mümkünse havuz kullanımı da hijyenik olmayacağı için önerilmez. ",
        },
        {
            title: "Aşılama işlemi uygulandıktan sonra nasıl beslenmeliyim?",
            content: "Aşılama sonrası uyulması gereken özel bir beslenme diyeti söz konusu değildir. Ancak vücudunuz kendisini gebeliğe hazırlamak için bazı vitamin ve besin maddelerine ihtiyaç duymaktadır. Bu nedenle bu süreçte yeterli ve dengeli beslenmek gebe kalma şansınızı artırabileceği gibi gebeliğinizin de sağlıkla devam etmesini destekler. Detaylı bilgi için “Aşılama Sonrası Dikkat Edilmesi Gerekenler” modülünü izleyebilirsiniz. ",
        },

    ]


    return (
        <>
            <Navbar/>
            <div className={"flex justify-items-center items-center w-full h-full"}>
                <div className={"flex- flex-col w-full sm:py-12 md:py-20 lg:py-12 m-4 sm:px-4 md:px-8 lg:px-16 "}>
                    <span className={"divide-y-4 divide-black"}>
                        <p className={"font-bold font-serif text-5xl  text-center text-orange-500  mb-5 "}>Sıkça Sorulan Sorular</p>
                    </span>
                    <Accordion className={""} multiple>
                        {faqs.map(f => (

                            <AccordionTab className={"mt-4 mb-4"} header={<p
                                className={"text-orange-500 text-xl font-serif font-bold"}>{f.title}</p>}><p
                                className={"text-black text-xl font-serif text-justify"}>{f.content}</p></AccordionTab>

                        ))}
                    </Accordion>
                </div>


            </div>
        </>
    )
}

export default Faq;