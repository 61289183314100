import {Menubar} from "primereact/menubar";
import {useUserAuth} from "../Context/UserAuthContext";

export function Navbar() {

    const {user} = useUserAuth();

    const items = [
        {label: 'Anasayfa', icon: 'pi pi-fw pi-home',url:'/',style:{color:'white'}},
        {label: 'Videolar', icon: 'pi pi-fw pi-youtube',url:'/video'},
        {label: 'Hakkında', icon: 'pi pi-fw pi-info',url:'/hakkinda'},
        {label: 'İletişim', icon: 'pi pi-fw pi-envelope',url:'/iletisim'},
        {label: 'Sıkça Sorulan Sorular', icon: 'pi pi-fw pi-question',url:'/faqs'}
    ];

    const endNav = <div className={"flex flex-col flex-wrap justify-center items-center"}>
        {user.name && <span>

        <p className={"text-md font-bold text-orange-500"}>Hoşgeldin {user.name} {user.secondName}</p>
        </span> }
        <h3 className={"text-2xl font-bold text-orange-500"}> <a href={"/"}>asilamatedavisi.com </a></h3>
    </div>


    return(
        <>
            <Menubar  model={items} end={endNav}/>
        </>
    )
}

export default Navbar;