import {useEffect, useState} from "react";
import {getAllVideos, updateVideo} from "../Services/VideoService";
import {Card} from "primereact/card";
import ReactPlayer from "react-player";
import Navbar from "./Navbar";
import {Button} from "primereact/button";
import {useUserAuth} from "../Context/UserAuthContext";
import {updatePatient} from "../Services/PatientService";
import {getVideoIstatisticById, postVideoIstatistic, updateVideoIstatistic} from "../Services/PatientAndVideoService";
import {isEmpty} from "lodash";


export function UserWatchVideo() {

    const [videoList, setVideoList] = useState([{}]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [selectedVideoId, setSelectedVideoId] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState({});
    const {user, setUser} = useUserAuth();
    const [userVideos, setUserVideos] = useState([{}]);
    const [videoAndPatient, setVideoAndPatient] = useState({});
    const [desc, setDesc] = useState([]);


    useEffect(() => {
        getAllVideos().then(res => setVideoList(res.data)).catch(err => console.log(err));
        getVideoIstatisticById(user.patientId).then(res => {
            setUserVideos(res.data);
            setIsLoaded(true)
        }).catch(err => console.log(err));
    }, [])

    useEffect(() => {
        setSelectedVideo(videoList.find(v => v.videoId == selectedVideoId));
    }, [selectedVideoId])

    useEffect(() => {
        if (selectedVideoId && videoList) {
            const vp = userVideos.find(v => v.video.videoId == selectedVideoId && v.patient.patientId == user.patientId);
            setVideoAndPatient(vp);
            getDescString(selectedVideo.description)
        }


    }, [selectedVideo])

    const isEnded = () => {
        let nextVideo;
        // video and patient

        videoList.map((v,i) => {
            if (Object.is(v,selectedVideo))
            {
                if (videoList[i+1]){
                    nextVideo=videoList[i+1];
                }
            }
        });

        // UserWatchVideo Count Update
        let user2 = Object.assign({}, user)
        if (user2.videoCount <= selectedVideoId) {
            if (nextVideo) {
                user2.videoCount = nextVideo && parseInt(nextVideo.videoId)
                const maxVideoId = Math.max(videoList["videoId"]);
                if (user2.videoCount > maxVideoId) {
                    user2.videoCount = maxVideoId;
                }
                setUser(user2);
                updatePatient(user2)
                    .then(res => {
                        res.status === 200 ? window.alert("Ders başarı ile tamamlandı bir sonraki derse geçebilirsiniz")
                            : window.alert("Lütfen destek için iletişim menüsünden Hilal hanımla iletişime geçiniz. Hata KODU-1001")
                    })
                    .catch(err => console.log(err))

            } else {
                window.alert("Tebrikler Bütün Dersleri Bitirdiniz.")
            }

        }

        // video view count update
        let video2 = Object.assign({}, selectedVideo);
        video2.totalViewCount = parseInt(video2.totalViewCount) + 1;
        updateVideo(video2).then(res => setSelectedVideo(video2)).catch(err => console.log(err));

        if (videoAndPatient) {
            videoAndPatient.viewCount = parseInt(videoAndPatient.viewCount) + 1;
            updateVideoIstatistic(videoAndPatient)
                .then(res => setVideoAndPatient(res.data))
                .catch(err => console.log(err));
            getVideoIstatisticById(user.patientId).then(res => setUserVideos(res.data)).catch(err => console.log(err))

        } else {
            let vp = {patient: user, video: selectedVideo, viewCount: 1};
            postVideoIstatistic(vp).then(res => setVideoAndPatient(res.data))
                .catch(err => console.log(err));
            getAllVideos().then(res => setVideoList(res.data)).catch(err => console.log(err));
            getVideoIstatisticById(user.patientId).then(res => setUserVideos(res.data)).catch(err => console.log(err))
        }
        getVideoIstatisticById(user.patientId).then(res => setUserVideos(res.data)).catch(err => console.log(err))


    }
    const isEnabled = (activeVideo) => {
        if (activeVideo && activeVideo.modul && user) {
            if (user.videoCount > 3) {
                return !activeVideo.modul.toString().valueOf().includes("Modül-3/")
            }
            return true;
        }
    }

    const isEnabled2 = (activeVideo) => {
        if (activeVideo.modul) return ((activeVideo.videoId > user.videoCount));
    }

    const getDescString = (str) => {
        const str2 = (str.split("-"))
        setDesc(str2);
    }

    return (
        <>
            {isLoaded && <div>
                <Navbar></Navbar>
                <div className="grid lg:grid-cols-4 grid-cols-1 text-slate-900">
                    <div className={"max-h-screen overflow-y-scroll"}>
                        {isLoaded && videoList.map((video, i) =>
                            <div key={i} className={"m-4"}>
                                <Card className={"mb-2 flex flex-col w-full  "}

                                      footer={<a href={"#video"}>
                                          <Button id={video.videoId}
                                                  disabled={isEnabled(video) && isEnabled2(video)}
                                                  onClick={(e) => setSelectedVideoId(e.target.id)}
                                                  style={{fontWeight: 'bold'}}
                                                  className={"p-button-sm p-button-rounded p-button-warning"}>Eğitime
                                              Başla !</Button>
                                      </a>}
                                      title={<div className={"text-lg text-slate-900 text-amber-500 font-bold px-2 py-2"}><h2
                                          className="font-bold text-xl">{video.modul} </h2>
                                          <hr/>
                                          <h2>{video.title}</h2></div>}>
                                </Card>
                            </div>)} {!isLoaded && <p>Henüz Video Eklenmedi!</p>}
                    </div>

                    <div className={"w-full lg:w-5/6 col-span-3 container px-4 py-4 m-auto rounded-2xl"}>
                        {selectedVideoId !== null &&
                            <div className="px-4 py-4 flex flex-col justify-center items-center shadow-xl rounded-2xl">
                                <div>
                                    <br/>
                                    <h5 className={"text-4xl text-amber-500 font-bold text-center "}>{selectedVideo.title}</h5>
                                    <br/>
                                    <hr/>
                                </div>

                                <div id="video" className={"flex flex-col justify-center items-center h-full w-full"}>
                                    <div className="md:w-3/5 lg:w-4/5 w-full h-full mt-5 ">
                                        <ReactPlayer
                                            url={selectedVideo.url}
                                            className="react-player mt-5"
                                            width="100%"
                                            controls={true}
                                            onEnded={() => isEnded()}
                                            volume={100}

                                        />
                                    </div>
                                    <div
                                        className={"flex flex-col self-start items-center w-full h-full text-black font-bold mt-5 px-5 py-5 "}>
                                        <h2 className="text-3xl">{"Video İçeriği"}</h2>
                                        <div
                                            className="md:w-3/5 lg:w-4/5 w-full ml-4 text-left mt-3 px-4 py-4">
                                            <hr className={"text-black"}/>
                                            {desc && desc.map((v, i) => <p key={i}
                                                                           className="text-left ">{v !== "" && v[0] === "*" ?
                                                <h1 className={"text-md lg:text-md text-slate-900 mb-[-20px]"}>{v.substring(1)}   <hr/></h1>

                                                : <h5 className={"text-md lg:text-md text-slate-900 mb-[-20px]"}>-{v}<hr/></h5>}<br/>
                                            </p>)}
                                        </div>
                                    </div>
                                </div>

                            </div>}
                        {!selectedVideoId && <div className={"px-4 py-4 shadow-xl w-full h-full flex flex-col justify-center items-center rounded-xl"}>

                            <ul className={"font-serif w-full h-full "}>
                                <li>
                                    <p className={"text-black font-bold text-3xl mb-2"}>-Lütfen izlemek istediğiniz
                                        videoyu seçiniz...</p>
                                </li>
                                <li>
                                    <p className={"text-black font-bold text-2xl  mb-2"}>-Videolar sıralı bir şekilde
                                        izlenmektedir.</p>
                                </li>
                                <li>
                                    <p className={"text-black font-bold text-xl "}>-Modül-3 'ün ilk videosunu izledikten
                                        sonra Modül-3 'ün alt videolarını kullandığınız ilaç türüne göre
                                        izleyebilirsiniz.</p>
                                </li>
                            </ul>
                        </div>
                        }
                    </div>
                </div>

            </div>}{!isLoaded && <p>Yükleniyor...</p>}
        </>
    )
}

export default UserWatchVideo;