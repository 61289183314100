import {useContext, createContext, useState, useEffect} from 'react';

const UserAuthContext = createContext();

export const UserAuthProvider = ({children}) => {

    const [userAuth, setUserAuth] = useState(false);
    const [user,setUser] = useState({});
    const [isAdmin,setIsAdmin]=useState(false);


    useEffect(() => {
        return () => {
           if(user.role === "admin") {
               setIsAdmin(true);
           }
        };
    }, [user]);


    const values = {
        userAuth,
        setUserAuth,
        user,
        setUser,
        isAdmin,
        setIsAdmin
    }


    return (
        <UserAuthContext.Provider value={values}> {children} </UserAuthContext.Provider>
    )
}

export const useUserAuth = () => useContext(UserAuthContext);