import axios from "axios";


const BASE_URI = process.env.REACT_APP_BASE_PATH.toString();

async function getAllVideos() {
    return await axios.get(BASE_URI + "video").then(res => res.status === 200 ? res : false)
        .catch(err => console.log(err));
}


async function getVideoById(id) {
    return await axios.get(BASE_URI + "video/"+ id).then(res => res.status === 200 ? res : false)
        .catch(err => console.log(err));
}

async function saveOrUpdateVideo(video) {
    return await axios.post(BASE_URI + "video",video).then(res => res.status === 200 ? res : false)
        .catch(err => console.log(err));
}

async function updateVideo(video) {
    return await axios.post(BASE_URI + "update/video",video).then(res => res.status === 200 ? res : false)
        .catch(err => console.log(err));
}

async function deleteVideo(videoId) {
    return await axios.delete(BASE_URI+"video/"+videoId)
        .then(res => res.status === 200 ? res : false)
        .catch(err => console.log(err))
}


export {getAllVideos,saveOrUpdateVideo,deleteVideo,getVideoById,updateVideo}

