import {useEffect, useState} from "react";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {DataTable} from "primereact/datatable";
import React from "react";
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {Column} from "primereact/column";
import {deletePatient, getAllPatient, saveOrUpdatePatient} from "../Services/PatientService";
import {Dropdown} from "primereact/dropdown";
import {getVideoIstatisticById} from "../Services/PatientAndVideoService";



export function StudentPage() {

    const [patientData, setPatientData] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [createStudentDialogVisible, setCreateStudentDialogVisible] = useState(false);
    const [deleteStudentDialogVisible, setDeleteStudentDialogVisible] = useState(false);
    const [istatisticDialog, setIstatisticDialog] = useState(false);

    const [name, setName] = useState("");
    const [secondName, setSecondName] = useState("");
    const [identityNumber, setIdentityNumber] = useState("");
    const [telephoneNumber, setTelephoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [videoCount, setVideoCount] = useState(1);
    const [role, setRole] = useState("user");
    const [testGroup, setTestGroup] = useState("");
    const [submitButton, setSubmitButton] = useState(false)
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [selectedUserVideos, setSelectedUserVideos] = useState([{}]);



    const clearForm = () => {
        setName("");
        setSecondName("");
        setIdentityNumber("");
        setTelephoneNumber("");
        setPassword("");
        setTestGroup("");
    }


    const validateForm = () => {
        if (name && secondName && identityNumber && identityNumber.length === 11 && telephoneNumber.length === 11 && password && videoCount && role && testGroup) {
            setSubmitButton(true);
        } else {
            setSubmitButton(false)
        }
    }

    useEffect(() => {
        validateForm()
    }, [name, secondName, identityNumber, telephoneNumber, password, videoCount, role, testGroup]);

    useEffect(() => {
        getPatients();
    }, []);

    useEffect(() => {
        if (selectedUser) {
            getVideoIstatisticById(selectedUser.patientId).then(res => setSelectedUserVideos(res.data));
        }
    }, [selectedUser]);


    const getPatients = () => {
        getAllPatient().then(res => setPatientData(res.data))
    }

    const denyGrubItems = [
        {name: "Deney - 1", value: "Deney - 1"},
        {name: "Deney - 2 ", value: "Deney - 2"}
    ]

    const openStudentDialog = () => {
        setCreateStudentDialogVisible(true);
    }

    const hideStudentDialog = () => {
        setCreateStudentDialogVisible(false);
    }
    const openDeleteStudentDialog = () => {
        if (selectedUser != null) {
            setDeleteStudentDialogVisible(true);
        } else {
            alert("Lütfen Silmek İstediğiniz Hastayı Seçiniz.")
        }
    }

    const hideDeleteStudentDialog = () => {
        setDeleteStudentDialogVisible(false);
    }

    const dialogHeader = <div className={"px-2 py-2 text-center"}><br/><h3>Yeni Hasta Ekleme Formu </h3></div>

    const deleteUserFooter = <div className={"m-3"}>
        <Button label={"Sil"}
                onClick={() => deletePatient(selectedUser.patientId).then(res => {
                    if (res.status === 200) {
                        getPatients();
                        setDeleteStudentDialogVisible(false);
                        setSelectedUser(null);
                    } else {
                        window.alert("Bir hata meydana geldi!");
                    }
                })}
                className={"p-button-danger"}/>
    </div>


    return (

        <div className={"flex flex-col w-full h-full"}>

            <div className={"flex flex-wrap mt-5 mb-5"}>
                <div>
                    <a className={"flex px-3 py-3 bg-emerald-600 text-center text-white rounded-xl m-2 hover:bg-emerald-500 cursor-pointer"}
                       onClick={openStudentDialog}>Hasta Ekle </a>
                </div>
                <div>
                    <a className={"flex px-3 py-3 bg-red-500 text-white text-center rounded-xl m-2 hover:bg-red-400 cursor-pointer"}
                       onClick={openDeleteStudentDialog}>Hasta sil </a>
                </div>

                <div className={"self-end justify-self-end"}>
                    <a className={"flex px-3 py-3 bg-amber-500 text-white text-center rounded-xl m-2 hover:bg-amber-400 cursor-pointer"}
                       onClick={() => setPasswordVisible(!passwordVisible)}>Şifreyi Göster/Gizle </a>
                </div>

                <div className={"self-end justify-self-end"}>
                    <a onClick={() => selectedUser && setIstatisticDialog(true)}
                       className={"flex px-3 py-3 bg-gray-500 text-white text-center rounded-xl m-2 hover:bg-slate-400 cursor-pointer"}
                    >Hasta İstatistikleri </a>
                </div>
            </div>

            <DataTable
                value={patientData}
                dataKey={"patientId"}
                paginator rows={5} rowsPerPageOptions={[5, 10, 25]}
                selectionMode={"checkbox"}
                selection={selectedUser}
                onSelectionChange={(e) => setSelectedUser(e.value)}
                responsiveLayout={"scroll"}
                globalFilterFields={['name', 'identityNumber', 'telephoneNumber', 'secondName']}
                emptyMessage={"Hasta Bulunamadı"}
            >
                <Column selectionMode="single" headerStyle={{width: '3em'}}></Column>
                <Column field="name" sortable header={"ADI"} className={"font-bold"}/>
                <Column field="secondName" sortable header={"SOYADI"} className={"font-bold"}/>
                <Column field="identityNumber" sortable  header={"TC Kimlik Numarası"} className={"font-bold"}/>
                <Column field="telephoneNumber" sortable header={"Telefon Numarası"} className={"font-bold"}/>
                <Column field="testGroup" header={"Test Grubu"} className={"font-bold"}/>
                <Column field={"videoCount"} header={"İzlenen Video Sayısı"} className={"font-bold"}/>
                {passwordVisible ? <Column field={"password"} header={"Şifre"} className={"font-bold"}/>
                    : false}
            </DataTable>

            <Dialog visible={istatisticDialog}
                    className={"bg-white flex flex-col justify-items-center items-center text-amber-500 max-h-96 overflow-auto"}
                    header={<p
                        className={"font-bold text-lg text-amber-500 px-4 py-2"}>{selectedUser && selectedUser.name} {selectedUser && selectedUser.secondName} İstatistikleri </p>}
                    onHide={() => setIstatisticDialog(false)}>
                <table className="border-collapse border border-slate-500 px-4 py-4">
                    <thead>
                    <tr className={""}>
                        <th className="border border-slate-600 px-8 py-2 text-left">İzlenme Sayısı</th>
                        <th className="border border-slate-600 px-2 py-2 text-left ">Modül</th>
                        <th className="border border-slate-600 px-2 py-2 text-left ">Video Başlığı </th>
                        <th className="border border-slate-600 px-2 py-2 text-left ">Video İçeriği </th>
                    </tr>
                    </thead>
                    <tbody>
                    {selectedUserVideos && selectedUserVideos.map((v,key) =>
                        <tr key={key} className={"font-semibold text-gray-700 px-2 py-2"}>
                            <td className="border border-slate-700 px-2 py-2">{v && v.viewCount}</td>
                            <td className="border border-slate-700 px-2 py-2">{v.video && v.video.modul}</td>
                            <td className="border border-slate-700 px-2 py-2">{v.video && v.video.title}</td>
                            <td className="border border-slate-700 px-2 py-2">{v.video && v.video.description.split("-").map(e => <p>{e}</p>)}</td>
                        </tr>
                    )}
                    </tbody>
                </table>

            </Dialog>


            <Dialog visible={createStudentDialogVisible}
                    className={"bg-white flex flex-col justify-items-center items-center"}
                    header={dialogHeader}
                    footer={<div className={"flex flex-wrap w-full"}>
                        <a className={"px-4 py-4 text-white rounded-xl bg-slate-700 hover:bg-slate-600 cursor-pointer mr-2 ml-2"}
                           onClick={clearForm}>Temizle</a>
                        {submitButton ? <a type={"submit"}
                                           className={"px-4 py-4 bg-sky-700 text-white rounded-xl hover:bg-sky-600 cursor-pointer"}
                                           onClick={() => {
                                               const newPatient = {
                                                   name,
                                                   secondName,
                                                   identityNumber,
                                                   telephoneNumber,
                                                   password,
                                                   testGroup,
                                                   videoCount,
                                                   role
                                               };
                                               saveOrUpdatePatient(newPatient)
                                                   .then(res => res.status === 200 ? getPatients()
                                                       : window.alert("Bir hata meydana geldi!"))
                                                   .catch(err => console.log(err))
                                               setCreateStudentDialogVisible(false);
                                               clearForm();
                                           }}>Kaydet</a> : false}
                    </div>}
                    onHide={hideStudentDialog}

            >

                <div className="card pl-4">
                    <div className="field grid">
                        <div className="col-4 md:col-4 mt-2">
                            <InputText maxLenght={20}
                                       value={name}
                                       id="name"
                                       placeholder={"AD"}
                                       onChange={(e) => {
                                           setName(e.target.value)
                                       }}/>
                        </div>
                        {name === "" ?
                            <p className={"text-xs text-red-500  text-left"}>Bu alan zorunludur.</p> : false}
                    </div>
                    <div className="field grid">

                        <div className="col-4 md:col-4 mt-2">
                            <InputText id="secondName"
                                       maxLength={20}
                                       validateOnly={true}
                                       placeholder={"SOYAD"}
                                       onChange={(e) => {
                                           setSecondName(e.target.value)
                                       }}
                                       value={secondName}/>
                        </div>
                        {secondName === "" ?
                            <p className={"text-xs text-red-500 text-left"}>Bu alan zorunludur.</p> : false}

                    </div>
                    <div className={"field grid"}>
                        <div className="col-4 md:col-4 mt-2">
                            <InputText id="identityNumber"
                                       placeholder={"TC KİMLİK NUMARASI"}
                                       onChange={(e) => {
                                           setIdentityNumber(e.target.value)
                                       }}
                                       value={identityNumber}
                                       maxLength={11}/>
                        </div>
                        {identityNumber === "" ?
                            <p className={"text-xs text-red-500 text-left px-1 mt-1"}>Bu alan zorunludur.</p> : false}

                    </div>
                    <div className={"field grid"}>
                        <div className="col-4 md:col-4 mt-2">
                            <InputText id="telephoneNumber"
                                       placeholder={"TELEFON NUMARASI"}
                                       value={telephoneNumber}
                                       onChange={(e) => {
                                           setTelephoneNumber(e.target.value)
                                       }}
                                       maxLength={11}/>
                        </div>
                        {telephoneNumber === "" ?
                            <p className={"text-xs text-red-500 text-left"}>Bu alan zorunludur.</p> : false}

                    </div>
                    <div className={"field grid"}>
                        <div className="col-4 md:col-4 mt-2">
                            <Password id="password"
                                      placeholder={"ŞİFRE"}
                                      value={password}
                                      onChange={(e) => {
                                          setPassword(e.target.value)
                                      }}/>
                        </div>
                        {password === "" ?
                            <p className={"text-xs text-red-500 text-left"}>Bu alan zorunludur.</p> : false}
                    </div>

                    <div className={"field grid w-full"}>
                        <div className="col-2 md:col-4 mt-2 w-full">
                            <Dropdown optionLabel="name" options={denyGrubItems}
                                      value={testGroup}
                                      onChange={(e) => setTestGroup(e.target.value)}
                                      placeholder="Deney Grubu"/>
                        </div>
                        {testGroup === "" ?
                            <p className={"text-xs text-red-500 text-left"}>Bu alan zorunludur.</p> : false}
                    </div>
                </div>

            </Dialog>

            <Dialog visible={deleteStudentDialogVisible}
                    stlye={{width: "250px"}}
                    footer={deleteUserFooter}
                    onHide={hideDeleteStudentDialog}>
                <h1 className={"px-4 font-bold text-center text-xl mb-2 text-red-700 opacity-80"}>Hasta Silme
                    Onayı</h1>
                <div className={"text-md px-4 py-4  font-medium bg-red-700 opacity-80 rounded-xl text-white"}>
                    {selectedUser ? <div><p>Adı:{selectedUser.name}</p>
                        <p>Soyadı:{selectedUser.secondName}</p>
                        <p>TC Kimlik Numarası:{selectedUser.identityNumber}</p>
                        <p>Telefon Numarası:{selectedUser.telephoneNumber}</p>
                        <p>Deney Grubu:{selectedUser.testGroup}</p></div> : <p></p>}
                </div>
                <br/>
                <h3 className={"px-4 font-bold "}>Seçili Hastayı Silmek İstediğinize Emin Misiniz? Bu İşlem Geri
                    Alınamaz.</h3>
            </Dialog>

        </div>
    )
}


export default StudentPage;