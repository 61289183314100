import {DataTable} from "primereact/datatable";
import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {useEffect, useState} from "react";
import {deleteVideo, getAllVideos, saveOrUpdateVideo} from "../Services/VideoService";
import {getVideoIstatisticById} from "../Services/PatientAndVideoService";


export function VideoPage() {

    const [allVideos, setAllVideo] = useState([{}]);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [addVideoVisible, setAddVideoVisible] = useState(false);
    const [deleteVideoVisible, setDeleteVideoVisible] = useState(false);
    const [url, setUrl] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [videoIstatisticVisible, setVideoIstatisticVisible] = useState(false);
    const [istatistic ,setIstatistic] = useState([{}]);
    const [modul,setModul] = useState("Modül-1");


    const getVideos = () => {
        getAllVideos().then(res => setAllVideo(res.data)).catch(err => console.log(err));
    }
    useEffect(() => {
        getVideos();
    }, []);

    useEffect(() => {
        if(selectedVideo){
            getVideoIstatisticById(selectedVideo.videoId)
                .then(res =>console.log(res) )
                .catch(err => console.log(err));
        }

    }, [selectedVideo]);


    const saveVideo = () => {
        const totalViewCount="0";
        const newVideo = {
            url,
            title,
            description,
            modul,
            totalViewCount
        }
        saveOrUpdateVideo(newVideo).then(res => res.status === 200 ? getVideos() : window.alert("Bir Hata Meydana Geldi"))
        setUrl("");
        setTitle("");
        setDescription("");
        setAddVideoVisible(false);
        getVideos()

    }

    const delVideo = () => {
        if (selectedVideo && selectedVideo.videoId) {
            deleteVideo(selectedVideo.videoId).then(res => {
                if (res.status === 200) {
                    getVideos();
                    setDeleteVideoVisible(false);
                    setSelectedVideo({});
                } else {
                    window.alert("Bir Hata Meydana Geldi!")
                }
            });
        } else {
            window.alert("Lütfen bir video seçiniz.");
        }
    }

    const dataTableHeader =
        <div className={"flex flex-wrap justify-start items-center w-full "}>
            <div className={"w-5/6 lg:w-auto "}>
                <a onClick={() => setAddVideoVisible(true)}
                   className={"flex px-3 py-3 bg-emerald-600 text-center text-white rounded-xl m-2 hover:bg-emerald-500 cursor-pointer"}>Video
                    Ekle</a>
            </div>
            <div className={"w-5/6 lg:w-auto"}>
                <a onClick={() => selectedVideo && setDeleteVideoVisible(true)}
                   className={"flex px-3 py-3 bg-red-500 text-white text-center rounded-xl m-2 hover:bg-red-400 cursor-pointer"}>Video
                    Sil</a>
            </div>
            <div className={"w-5/6 lg:w-auto"}>

                <a onClick={() => setVideoIstatisticVisible(true)}
                   className={"flex px-3 py-3 bg-amber-500 text-white text-center rounded-xl m-2 hover:bg-amber-400 cursor-pointer"}>İstatistik
                    Görüntüle</a>
            </div>
        </div>

    const addVideoDialogHeader =
        <span>
                <h3>Video Ekleme İşlemleri</h3>
            </span>

    const addVideoDialogFooter =
        <span>
                <Button label={" Kaydet"}
                        onClick={saveVideo}
                        className={"p-button-md p-button-success pi pi-check"}/>
            </span>
    const videoForm =
        <div className="card py-4">
            <h5>Video Ekleme Formu</h5>
            <div className="field grid">
                <div className="col-4 md:col-4">
                       <span className={"p-input-icon-left"}>
                            <i className={"pi pi-envelope"}/>
                            <InputText id="header"
                                       placeholder={"Başlık"}
                                       value={title}
                                       onChange={(e) => setTitle(e.target.value)}
                            ></InputText>
                        </span>
                </div>
            </div>
            <div className="field grid mt-2">
                <div className="col-4 md:col-4">
                        <span className={"p-input-icon-left"}>
                            <i className={"pi pi-info-circle"}/>
                            <InputText id="content"
                                       placeholder={"İçerik Bilgisi"}
                                       value={description}
                                       onChange={(e) => setDescription(() => e.target.value)}
                            ></InputText>
                        </span>

                </div>
            </div>
            <div className="field grid mt-2">
                <div className="col-4 md:col-4">
                     <span className="p-input-icon-left">
                        <i className="pi pi-youtube"/>
                        <InputText placeholder={"Youtube Linki"}
                                   value={url}
                                   onChange={(e) => setUrl(e.target.value)}
                        ></InputText>
                    </span>
                </div>
            </div>
            <div className="field grid mt-2">
                <div className="col-4 md:col-4">
                    <div className="border px-2 py-2 w-full h-full">
                    <select className="w-full h-full font-sans text-lg" placeholder="Modül Seçiniz" value={modul} onChange={e => setModul(e.target.value)}>
                            <option>Modül-1</option>
                            <option>Modül-2</option>
                            <option>Modül-3</option>
                            <option>Modül-3/A</option>
                            <option>Modül-3/B</option>
                            <option>Modül-3/C</option>
                            <option>Modül-3/D</option>
                            <option>Modül-3/E</option>
                            <option>Modül-3/F</option>
                            <option>Modül-4</option>
                        </select>
                    </div>

                </div>
            </div>

        </div>


    const videoIstatistic = <div className="card pl-4">
        Video İstatistic
    </div>


    return (

        <div className={"relative "}>
            {dataTableHeader}
            <DataTable value={allVideos}
                       dataKey={"videoId"}
                       paginator rows={10} rowsPerPageOptions={[10, 20, 50]}
                       selectionMode={"checkbox"}
                       selection={selectedVideo}
                       onSelectionChange={(e) => setSelectedVideo(e.value)}
                       responsiveLayout={"scroll"}

            >
                <Column selectionMode="single" headerStyle={{width: '3 rem'}} exportable={true}/>

                <Column field="title" header={"Başlık"} className={"font-bold"}/>
                <Column field="description" header={"İçerik"} className={"font-bold"}/>
                <Column field="url" header={"Video Linki"} className={"font-bold"}/>
                <Column field="modul" header={"Modul"} className={"font-bold"}/>
                <Column field="totalViewCount" header={"Toplam İzlenme Sayısı"} className={"font-bold"}/>


            </DataTable>
            <Dialog visible={addVideoVisible}
                    className={"w-5/6  lg:w-1/6"}
                    onHide={() => setAddVideoVisible(false)}
                    header={addVideoDialogHeader}
                    footer={addVideoDialogFooter}

            >
                {videoForm}

            </Dialog>
            <Dialog visible={deleteVideoVisible}
                    stlye={{width: "250px"}}
                    onHide={() => setDeleteVideoVisible(false)}
                    header={<h3>Seçili videoyu silmek istediğinize emin misiniz?</h3>}
                    footer={<Button label={"Sil"}
                                    className={"p-button-danger p-button-md"}
                                    onClick={delVideo}/>}
            >
                {selectedVideo && <div>
                    <p><strong> Başlık:</strong>{selectedVideo.title}</p>
                    <p><strong> Açıklama:</strong>{selectedVideo.description}</p>
                    <p><strong> URL:</strong>{selectedVideo.url}</p>
                </div>}

            </Dialog>

            <Dialog className={"w-5/6 lg:w-1/2 "}
                    visible={videoIstatisticVisible}
                    stlye={{width: "250px"}}
                    onHide={() => setVideoIstatisticVisible(false)}
                    header={<h3 className={"mr-2 text-amber-500"}>Video Detayları</h3>}
                    footer={<a onClick={() => setVideoIstatisticVisible(false)}
                    className={"bg-amber-500  text-white rounded-xl font-bold px-4 py-4 hover:cursor-pointer"}>Kapat</a>}
            >
                {selectedVideo && <div className={"flex flex-col bg-amber-500 w-full h-full text-white px-8 py-8 rounded-xl max-h-96 scroll overflow-scroll"}>
                    <div className={"py-2"}>
                        <p><strong> Başlık:</strong>{selectedVideo.title}</p>
                    </div>
                    <hr/>
                    <div className={"py-2"}>
                        <p><strong> Açıklama:</strong>{selectedVideo.description}</p>
                    </div>
                    <hr/>
                    <div className={"py-2"}>
                        <p><strong> URL:</strong>{selectedVideo.url}</p>
                    </div>
                    <hr/>
                    <div className={"py-2"}>
                        <p><strong> Toplam İzlenme Sayısı:</strong>{selectedVideo.totalViewCount}</p>
                    </div>
                    <hr/>



                </div>}

            </Dialog>
        </div>
    )

}


export default VideoPage;