import  {useUserAuth} from "../Context/UserAuthContext";
import {Navigate } from "react-router-dom";


export default function AdminPrivateRoute({children}) {
    const {isAdmin} = useUserAuth();

    if(!isAdmin) {
        return <Navigate to="/login"></Navigate>
    }
    return children
}